import { app as api } from '@ekuaibao/whispered';
import { Fetch, Resource } from '@ekuaibao/fetch';
import key from './key';

const logs = new Resource('/api/v1/organization/logs');
const role = new Resource('/api/v1/organization/roles');
const staff = new Resource('/api/v1/organization/staffs');
const roleGroup = new Resource('/api/v1/organization/roleGroups');
const roleV2 = new Resource('/api/v2/organization');

export function getLog(data) {
  return {
    type: key.GET_LOG_LIST,
    payload: logs.POST('/roles/log', data),
  };
}

export function saveRole(data, done) {
  return {
    type: key.SAVE_ROLE,
    payload: role.POST(data),
    done,
  };
}

export function deleteRole(data, done) {
  return {
    type: key.DEL_ROLE,
    payload: role.DELETE('/$id', data),
    done,
  };
}

export function getStaffsByRole(data, done) {
  let params = {
    roleDefId: data.id,
  };
  return {
    type: key.GET_STAFFS_BY_ROLE,
    payload: staff.GET('/byRole', params),
    done,
  };
}

export function updateRole(data, done) {
  return {
    type: key.UPDATE_ROLE,
    payload: role.PUT('/$id', data),
    done,
  };
}

export function deleteStaffsRole(data, done) {
  return {
    type: key.DEL_STAFFS_ROLE,
    payload: role.DELETE('/$id/staffs', data),
    done,
  };
}

export function authorizeStaffs(data, done) {
  return {
    type: key.AUTHORIZE_STAFFS,
    payload: role.PUT('/$id/staffs', data),
    done,
  };
}

export function saveRoleGroup(data, done) {
  return {
    type: key.SAVE_ROLE_GROUP,
    payload: roleGroup.POST(data),
    done,
  };
}

export function updateRoleGroup(data, done) {
  return {
    type: key.UPDATE_ROLE_GROUP,
    payload: roleGroup.PUT('/$id', data),
    done,
  };
}

export function deleteRoleGroup(data, done) {
  return {
    type: key.DEL_ROLE_GROUP,
    payload: roleGroup.DELETE('/$id', data),
    done,
  };
}

export function getRoleScope() {
  return {
    type: key.GET_ROLE_SCOPE,
    payload: role.GET('/scope'),
  };
}
export function getRoleSyncConfig(plat) {
  return {
    type: key.GET_ROLE_SYNC_SETTINGS,
    payload: roleV2.GET('/roleSync/config', { plat }),
  };
}
export function setRoleSyncConfig(value) {
  return {
    type: key.SET_ROLE_SYNC_CONFIG,
    payload: roleV2.POST('/roleSync/config', value),
  };
}

export function handleExport(id) {
  let roleId = encodeURIComponent(id);
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId);
  let syncUrl = `${Fetch.fixOrigin(
    location.origin,
  )}/api/v1/organization/roles/excel/export/$${roleId}?corpId=${ekbCorpId}`;
  api.emit('@vendor:download', syncUrl);
}
