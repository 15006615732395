// @i18n-ignore-all
import { handleExport } from './role-action'

export default [
  {
    id: '@role',
    path: '/role',
    ref: '/',
    onload: () => import('./role-view'),
    reducer: () => import('./role-reducer'),
    'export:roleList'(id) {
      return handleExport(id)
    }
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'role',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        weight: 2,
        label: '角色管理',
        href: '/role',
        icon: 'role-manage'
      }
    ]
  },
  {
    point: '@@layers',
    prefix: '@role',
    onload: () => import('./modal')
  }
]
