/**
 * @providesModule key
 * @flow
 * Created by XuTian on 16/11/2016.
 */

export const ID = '@role'

export default {
  ID: ID,
  GET_LOG_LIST: `${ID}/GET_LOG_LIST`,
  SAVE_ROLE: `${ID}/SAVE_ROLE`,
  DEL_ROLE: `${ID}/DEL_ROLE`,
  GET_STAFFS_BY_ROLE: `${ID}/GET_STAFFS_BY_ROLE`,
  UPDATE_ROLE: `${ID}/UPDATE_ROLE`,
  DEL_STAFFS_ROLE: `${ID}/DEL_STAFFS_ROLE`,
  AUTHORIZE_STAFFS: `${ID}/AUTHORIZE_STAFFS`,
  SYNC_STAFFS: `${ID}/SYNC_STAFFS`,
  SAVE_ROLE_GROUP: `${ID}/SAVE_ROLE_GROUP`,
  MOVE_ROLE: `${ID}/MOVE_ROLE`,
  DEL_ROLE_GROUP: `${ID}/DEL_ROLE_GROUP`,
  UPDATE_ROLE_GROUP: `${ID}/UPDATE_ROLE_GROUP`,
  GET_ROLE_SYNC_SETTINGS: `${ID}/GET_ROLE_SYNC_SETTINGS`,
  SET_ROLE_SYNC_CONFIG: `${ID}/SET_ROLE_SYNC_CONFIG`,
  GET_ROLE_SCOPE: `${ID}/GET_ROLE_SCOPE`
}
